import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, { label: "提示文字" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input, {
        modelValue: _ctx._placeholder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._placeholder) = $event)),
        size: "default",
        placeholder: "请设置提示语"
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}